import { create } from "zustand";
import { CompletedStory, Msg, UserPhysical, UserGoal, UserEnergy, UserExercise, UserHeardOfFuncSyms, UserLowMood, UserSleepTrouble, UserStoppedActivities, UserWorrying } from "./types";


type AppState = {
	devMode: boolean;
	appNoStory: boolean;

	currProgramID?: string;

	currStoryID: string;
	currStoryFlowID: string;
	convo: Msg[];
	isTyping: boolean;
	showTyping: boolean;

	userName: string;
	dateOfBirth?: Date;
	goal: UserGoal;
	physicalSymptoms: UserPhysical[];
	otherPhysicalSymptoms: string;
	heardOfFuncSyms: UserHeardOfFuncSyms;
	worrying: UserWorrying;
	stoppedActivities: UserStoppedActivities;
	stoppedActivitiesText: string;
	exercise: UserExercise;
	lowMood: UserLowMood;
	energy: UserEnergy;
	sleepTrouble: UserSleepTrouble;

	storyHistory: CompletedStory[];
};

type AppActions = {
	resetStore: () => void;

	setDevMode: (devMode: boolean) => void;
	setAppNoStory: (appLoading: boolean) => void;

	setCurrProgramID: (currProgramID: string) => void;

	setCurrStoryID: (currStoryID: string) => void;
	setCurrStoryFlowID: (currStoryFlowID: string) => void;
	setConvo: (convo: Msg[]) => void;
	pushMessage: (msg: Msg) => void;
	removeMessagesSince: (key: string) => void;

	setUserName: (userName: string) => void;
	setDateOfBirth: (dateOfBirth: Date) => void;
	setGoal: (goal: UserGoal) => void;
	setPhysicalSymptoms: (physicalSymptoms: UserPhysical[]) => void;
	setOtherPhysicalSymptoms: (otherPhysicalSymptoms: string) => void;
	setHeardOfFuncSyms: (heardOfFuncSyms: UserHeardOfFuncSyms) => void;
	setWorrying: (worrying: UserWorrying) => void;
	setStoppedActivities: (stoppedActivities: UserStoppedActivities) => void;
	setStoppedActivitiesText: (stoppedActivitiesText: string) => void;
	setExercise: (exercise: UserExercise) => void;
	setLowMood: (lowMood: UserLowMood) => void;
	setEnergy: (energy: UserEnergy) => void;
	setSleepTrouble: (sleepTrouble: UserSleepTrouble) => void;

	pushStoryHistory: (completedStory: CompletedStory) => void;

	simulateTyping: (messageLength: number, responseLength: number) => Promise<void>;
};


const initialState: AppState = {
	devMode: false,
	appNoStory: false,

	currProgramID: undefined,

	currStoryID: "",
	currStoryFlowID: "",
	convo: [],
	isTyping: false,
	showTyping: false,

	userName: "Tester",
	dateOfBirth: undefined,
	goal: UserGoal.Body,
	physicalSymptoms: [],
	otherPhysicalSymptoms: "",
	heardOfFuncSyms: UserHeardOfFuncSyms.NoNotSure,
	worrying: UserWorrying.None,
	stoppedActivities: UserStoppedActivities.None,
	stoppedActivitiesText: "",
	exercise: UserExercise.Some,
	lowMood: UserLowMood.None,
	energy: UserEnergy.Medium,
	sleepTrouble: UserSleepTrouble.None,

	storyHistory: []
};

export const useStore = create<AppState & AppActions>(
	(set, get) => ({
		...initialState,

		resetStore: () => set(initialState),

		setDevMode: devMode => set({ devMode }),
		setAppNoStory: appNoStory => set({ appNoStory }),

		setCurrProgramID: currProgramID => set({ currProgramID }),

		setCurrStoryID: currStoryID => set({ currStoryID }),
		setCurrStoryFlowID: currStoryFlowID => set({ currStoryFlowID }),
		setConvo: convo => set({ convo }),
		pushMessage: msg => {
			const convo = [...get().convo];
			convo.push(msg);
			get().setConvo(convo);
		},
		removeMessagesSince: key => {
			const convo = [...get().convo];
			const index = convo.findIndex(msg => msg.key === key);
			if(!index) return;
			convo.splice(index);
			get().setConvo(convo);
		},

		setUserName: userName => set({ userName }),
		setDateOfBirth: dateOfBirth => set({ dateOfBirth }),
		setGoal: goal => set({ goal }),
		setPhysicalSymptoms: physicalSymptoms => set({ physicalSymptoms }),
		setOtherPhysicalSymptoms: otherPhysicalSymptoms => set({ otherPhysicalSymptoms }),
		setHeardOfFuncSyms: heardOfFuncSyms => set({ heardOfFuncSyms }),
		setWorrying: worrying => set({ worrying }),
		setStoppedActivities: stoppedActivities => set({ stoppedActivities }),
		setStoppedActivitiesText: stoppedActivitiesText => set({ stoppedActivitiesText }),
		setExercise: exercise => set({ exercise }),
		setLowMood: lowMood => set({ lowMood }),
		setEnergy: energy => set({ energy }),
		setSleepTrouble: sleepTrouble => set({ sleepTrouble }),

		pushStoryHistory: completedStory => {
			set(state => ({ storyHistory: [...state.storyHistory, completedStory] }));
		},

		simulateTyping: async (messageLength, responseLength) => {
			set({ isTyping: true });
			await new Promise(resolve => setTimeout(resolve, 5 * messageLength));
			if (responseLength < 10) {
				set({ isTyping: false });
				return;
			};
			set({ showTyping: true });
			await new Promise(resolve => setTimeout(resolve, 5 * responseLength + 50));
			set({ isTyping: false, showTyping: false });
		}
	})
);
