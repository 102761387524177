import { useState } from "react";
import { useStore } from "../lib/store";
import { Msg, MsgSaveAs, MsgSender, MsgType, UserInput } from "../lib/types";
import getResponse from "../lib/getResponse";
import { key } from "../lib/helpers";
import Button from "../components/Button";
import FeatherIcon from "feather-icons-react";


type HybridButtonInputProps = {
	input: UserInput[];
};

const HybridButtonInput = ({ input }: HybridButtonInputProps) => {
	const pushMessage = useStore(state => state.pushMessage);
	const setOtherPhysicalSymptoms = useStore(state => state.setOtherPhysicalSymptoms);
	const setStoppedActivitiesText = useStore(state => state.setStoppedActivitiesText);


	// Set button selection state
	const initPressedState = () => {
		const state: boolean[] = [];
		for (let n = 0; n < input.length; n++) {
			state.push(false);
		}
		return state;
	};
	const [pressed, setPressed] = useState(initPressedState);

	const handleSelect = (index: number) => {
		const newPressed = initPressedState();
		newPressed[index] = !pressed[index];
		setPressed(newPressed);
	};


	// Set text input state
	const [textDraft, setTextDraft] = useState("");


	// Send a hybrid message
	const sendHybridMessage = () => {
		const button = input[pressed.findIndex(el => el === true)];

		// If we're in hybrid mode and the input field is empty, don't send the message
		if(button && button.effect?.hybrid && textDraft === "") return;

		const content = button.effect?.hybrid ? button.content[0] + " " + textDraft : button.content[0];
		const saveAs = button.effect?.saveAs;

		// Handle the special case of saving other variables
		if (saveAs === MsgSaveAs.PhysicalSymsOther) setOtherPhysicalSymptoms(content);
		else if (saveAs === MsgSaveAs.StopDoingActivitiesText) setStoppedActivitiesText(content);

		// Construct and send the message
		const msg: Msg = {
			key: key(),
			id: button.id,
			type: MsgType.UserButton,
			sender: MsgSender.User,
			date: Date.now(),
			content
		};
		pushMessage(msg);

		// Reset the text input field
		setTextDraft("");

		// Get a response
		getResponse(msg);
	};


	// Render the component
	return (
		<div>
			<div className="w-full flex flex-row flex-wrap justify-center gap-2 mb-4">
				{ input.map((button, index) => (
					<Button
						key={`button-${index}`}
						className={`px-[15px] py-2.5 rounded-3xl ${pressed[index] && "!bg-apricot-500 !border-apricot-700"}`}
						onClick={() => handleSelect(index)}
					>
						{ button.content[0] }
					</Button>
				))}
			</div>
			<div className="w-full flex flex-row">
				<input
					type="text"
					value={textDraft}
					onChange={e => setTextDraft(e.target.value)}
					onKeyDown={e => e.key === "Enter" && sendHybridMessage()}
					placeholder={"Write your reply here"}
					disabled={!input[pressed.findIndex(el => el === true)]?.effect?.hybrid}
					className={`grow h-12 mr-4 rounded-3xl bg-light-300 disabled:bg-gray-300 border-light-700 disabled:border-gray-500 border-2 px-5 font-nu text-base`}
				/>
				<Button
					fillColor="red-500"
					borderColor="red-900"
					className="h-12 w-12 !rounded-full p-0"
					disabled={!pressed.includes(true)}
					onClick={sendHybridMessage}
				>
					<FeatherIcon
						icon="send"
						size={25}
						stroke="white"
						className="-ml-[3px] -mt-[3px]"
					/>
				</Button>
			</div>
		</div>
	);
};

export default HybridButtonInput;
