import { useState } from "react";
import { useStore } from "../lib/store";
import { Msg, MsgSaveAs, MsgSender, MsgType, UserInput, UserPhysical } from "../lib/types";
import { arrayToPhrase, capFirstLetter, key } from "../lib/helpers";
import getResponse from "../lib/getResponse";
import Button from "../components/Button";
import FeatherIcon from "feather-icons-react";


type ListInputProps = {
	input: UserInput;
};

const ListInput = ({ input }: ListInputProps) => {
	const pushMessage = useStore(state => state.pushMessage);
	const setPhysicalSymptoms = useStore(state => state.setPhysicalSymptoms);


	// Create options
	const options: [string, string | undefined][] = input.content.map((value, index) => [value, input.effect?.payload?.[index]]);
	if (input.effect?.listOther) options.push(["Something else", "other"]);
	if (input.effect?.listUnsure) options.push(["I'm not sure", "unsure"]);
	if (input.effect?.listNone) options.push(["None of these", "none"]);


	// Button UI state
	const initPressedState = () => {
		const state: boolean[] = [];
		for (let n = 0; n < options.length; n++) {
			state.push(false);
		}
		return state;
	};
	const [pressed, setPressed] = useState(initPressedState);
	const selectedItems = [...options].filter((_, index) => pressed[index]);


	// Handle interaction
	const handleSelect = (label: string, index: number) => {
		let newPressed: boolean[];
		if(label === "I'm not sure" || label === "None of these") {
			newPressed = initPressedState();
		}
		else {
			newPressed = [...pressed];
		}
		newPressed[index] = !pressed[index];
		setPressed(newPressed);
	};


	// Send a checklist message
	const sendUserListMessage = () => {
		// Save any content that needs saving
		const saveAs = input.effect?.saveAs;
		if(saveAs) {
			const savedItems = selectedItems.map(item => item[1] ?? item[0]);
			if (saveAs === MsgSaveAs.PhysicalSyms) setPhysicalSymptoms(savedItems as UserPhysical[]);
		}
		
		// Construct and send the message
		const selectedItemLabels = selectedItems.map(item => item[0].toLowerCase());
		const selectedItemPayloads = selectedItems.map(item => (item[1] ?? item[0]).toLowerCase());

		const msg: Msg = {
			key: key(),
			id: input.id,
			type: MsgType.UserList,
			sender: MsgSender.User,
			date: Date.now(),
			content: capFirstLetter(arrayToPhrase(selectedItemLabels)),
			meta: {
				rawListPayload: selectedItemPayloads
			}
		};
		pushMessage(msg);

		// Get a response
		getResponse(msg);
	};


	// Render the component
	return (<>
		<div className="flex flex-row flex-wrap gap-2">
			{ options.map(([label, _], index) => (
				<Button
					key={index}
					disabled={
						(selectedItems.map(item => item[0]).includes("I'm not sure") && label !== "I'm not sure") ||
						(selectedItems.map(item => item[0]).includes("None of these") && label !== "None of these")
					}
					className={`px-[15px] py-2.5 rounded-3xl ${pressed[index] && "!bg-apricot-500 !border-apricot-700"}`}
					onClick={() => { handleSelect(label, index) }}
				>
					{ label }
				</Button>
			)) }
		</div>

		<div className="h-12 flex flex-row-reverse flex-wrap items-center mt-1 ml-3">
			<Button
				fillColor="red-500"
				borderColor="red-900"
				className="h-12 w-12 mb-2 p-0 self-end !rounded-3xl"
				onClick={sendUserListMessage}
				disabled={selectedItems.length === 0}
			>
				<FeatherIcon
					icon="send"
					size={25}
					stroke={"white"}
					className="-ml-[3px] -mt-[3px]"
				/>
			</Button>
			<span className="absolute w-[200px] mr-16 text-right text-dark-500">
				Select all that apply.
			</span>
		</div>
	</>);
};

export default ListInput;
