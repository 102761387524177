import { useState } from "react";
import ChatItem from "./ChatItem";
import { useStore } from "../lib/store";
import { Msg, MsgSaveAs, MsgSender, MsgType, UserEnergy, UserExercise, UserGoal, UserHeardOfFuncSyms, UserInput, UserLowMood, UserSleepTrouble, UserStoppedActivities, UserWorrying } from "../lib/types";
import { key } from "../lib/helpers";
import getResponse from "../lib/getResponse";
import Button from "../components/Button";
import HybridButtonInput from "./HybridButtonInput";
import ListInput from "./ListInput";
import FeatherIcon from "feather-icons-react";
import { format } from "date-fns";


const ChatWindow = () => {
	const devMode = useStore(state => state.devMode);
	const appNoStory = useStore(state => state.appNoStory);
	const convo = useStore(state => state.convo);
	const showTyping = useStore(state => state.showTyping);
	const setDevMode = useStore(state => state.setDevMode);
	const pushMessage = useStore(state => state.pushMessage);
	const setUserName = useStore(state => state.setUserName);
	const setDateOfBirth = useStore(state => state.setDateOfBirth);
	const setGoal = useStore(state => state.setGoal);
	const setOtherPhysicalSymptoms = useStore(state => state.setOtherPhysicalSymptoms);
	const setHeardOfFuncSyms = useStore(state => state.setHeardOfFuncSyms);
	const setWorrying = useStore(state => state.setWorrying);
	const setStoppedActivities = useStore(state => state.setStoppedActivities);
	const setStoppedActivitiesText = useStore(state => state.setStoppedActivitiesText);
	const setExercise = useStore(state => state.setExercise);
	const setSleepTrouble = useStore(state => state.setSleepTrouble);
	const setEnergy = useStore(state => state.setEnergy);
	const setLowMood = useStore(state => state.setLowMood);


	// Handle user text input
	const [userTextDraft, setUserTextDraft] = useState("");


	// Check input type
	const lastMessage = convo[convo.length - 1];
	const buttonInput = lastMessage?.input && lastMessage?.input[0].type === MsgType.UserButton;
	const hybridButtonInput = buttonInput && lastMessage.input!.filter(el => el.effect?.hybrid).length > 0;
	const listInput = lastMessage?.input && lastMessage?.input[0].type === MsgType.UserList;
	const textInput = lastMessage?.input && lastMessage?.input[0].type === MsgType.UserText;
	const dateInput = lastMessage?.input && lastMessage?.input[0].type === MsgType.UserDate;
	const timeInput = lastMessage?.input && lastMessage?.input[0].type === MsgType.UserTime;
	const programPicker = lastMessage?.input && lastMessage?.input[0].type === MsgType.PickProgram;
	const questPicker = lastMessage?.input && lastMessage?.input[0].type === MsgType.PickQuest;
	const storyFinished = lastMessage?.input && lastMessage?.input[0].type === MsgType.Output;


	// Send a single-select button message
	const sendButtonMessage = (button: UserInput) => {
		const content = button.content[0];
		const payload = button.effect?.payload;

		// Handle side-effects
		if (button.effect?.saveAs === MsgSaveAs.Goal) setGoal((payload ?? content) as UserGoal);
		else if (button.effect?.saveAs === MsgSaveAs.HeardOfFuncSyms) setHeardOfFuncSyms((payload ?? content) as UserHeardOfFuncSyms);
		else if (button.effect?.saveAs === MsgSaveAs.PhysicalSymsWorrying) setWorrying((payload ?? content) as UserWorrying);
		else if (button.effect?.saveAs === MsgSaveAs.StopDoingActivities) setStoppedActivities((payload ?? content) as UserStoppedActivities);
		else if (button.effect?.saveAs === MsgSaveAs.ExerciseLevel) setExercise((payload ?? content) as UserExercise);
		else if (button.effect?.saveAs === MsgSaveAs.LowMood) setLowMood((payload ?? content) as UserLowMood);
		else if (button.effect?.saveAs === MsgSaveAs.EnergyLevel) setEnergy((payload ?? content) as UserEnergy);
		else if (button.effect?.saveAs === MsgSaveAs.SleepTrouble) setSleepTrouble((payload ?? content) as UserSleepTrouble);

		// Construct and send the message
		const msg: Msg = {
			key: key(),
			id: button.id,
			type: MsgType.UserButton,
			sender: MsgSender.User,
			date: Date.now(),
			content
		};
		pushMessage(msg);

		// Get a response
		getResponse(msg);
	};


	// Handle sending user input messages
	const sendTextMessage = () => {
		const userInput = userTextDraft.trim();

		// Don't do anything when the text field is empty and assert that the text field exists
		if(!lastMessage?.input || !userInput) return;

		// Get the relevant fields
		const userInputField = lastMessage.input[0];
		const saveAs = userInputField.content[0];
		let content: string;

		// Handle the special case of user name anonymisation and the Dev Mode easter egg
		if (saveAs === MsgSaveAs.Name) {
			userInput === "Bayes meeting room" ? setDevMode(true) : setDevMode(false);
			setUserName(userInput);
			content = "%NAME%";
		}
		// In all other cases, we want to save what the user wrote
		else content = userInput;

		// Handle the special case of saving other variables
		if (saveAs === MsgSaveAs.PhysicalSymsOther) setOtherPhysicalSymptoms(userInput);
		else if (saveAs === MsgSaveAs.StopDoingActivitiesText) setStoppedActivitiesText(userInput);

		// Construct and send the message
		const msg: Msg = {
			key: key(),
			id: userInputField.id,
			type: MsgType.UserText,
			sender: MsgSender.User,
			date: Date.now(),
			content: content
		};
		pushMessage(msg);

		// Reset the input field
		setUserTextDraft("");

		// Get a response
		getResponse(msg);
	};

	const sendDateTimeMessage = () => {
		// Assert that the input exists
		if(!lastMessage?.input) return;
	
		// Use this moment as a placeholder date input
		const userInput = new Date();

		// Get the relevant fields
		const userInputField = lastMessage.input[0];
		const saveAs = userInputField.content[0];

		// Parse the content into a readable format
		let content = lastMessage.type === MsgType.UserDate ? format(userInput, "d MMMM yyyy") : format(userInput, "HH:mm");

		// Handle the special case of saving variables
		if (saveAs === MsgSaveAs.DoB) {
			setDateOfBirth(userInput);
			content = "%DATEOFBIRTH%";
		}

		// Construct and send the message
		const msg: Msg = {
			key: key(),
			id: userInputField.id,
			type: MsgType.UserDate,
			sender: MsgSender.User,
			date: Date.now(),
			content: content
		};
		pushMessage(msg);

		// Get a response
		getResponse(msg);
	};

	const sendQuestMessage = (buttonID: string) => {
		const msg: Msg = {
			key: key(),
			id: buttonID,
			type: MsgType.UserButton,
			sender: MsgSender.User,
			date: Date.now(),
			content: "New quest started!"
		};
		pushMessage(msg);
		getResponse(msg);
	};


	return (<>
		<div className="relative w-full max-w-[720px] h-full grow bg-light-300 border-2 border-light-700 rounded-3xl overflow-hidden">
			{ devMode === true &&
				<div className="absolute top-2 right-2 px-2 bg-night-300 border-2 border-night-500 rounded-full">
					<span className="font-bold text-white text-sm uppercase">
						Dev mode
					</span>
				</div>
			}

			{ appNoStory ?
				<div className="mt-12 text-center">
					Welcome to the UB-OK test app!
					<br /><br />
					Generate a test link in Botany to get started.
				</div>
			:
				<div className="h-full flex flex-col-reverse overflow-auto">
					<div className="flex flex-col w-full pt-4">
						{ convo.map((msg, msgIndex, msgList) => (
							<ChatItem
								key={msgIndex}
								msg={msg}
								msgIndex={msgIndex}
								msgList={msgList}
							/>
						)) }
					</div>
				</div>
			}
		</div>

		<div className="relative w-full max-w-[720px] min-h-[100px] shrink-0 py-4 mb-4">
			{ ((buttonInput && !hybridButtonInput) || programPicker) &&
				<div className="w-full flex flex-row flex-wrap justify-center gap-2">
					{ lastMessage.input!.map((button, buttonIndex) => (
						<Button
							key={`button-${buttonIndex}`}
							className="px-[15px] py-2.5 rounded-3xl"
							onClick={() => { sendButtonMessage(button) }}
						>
							{ programPicker ? "Continue" : button.content[0] }
						</Button>
					))}
					{ programPicker &&
						<p className="text-center italic">
							In the app, the user would at this point see a pop-up with a preview of their personal programme.
						</p>
					}
				</div>
			}

			{ hybridButtonInput && <HybridButtonInput input={lastMessage.input!} /> }

			{ listInput && <ListInput input={lastMessage.input![0]} /> }

			{ textInput &&
				<div className="w-full flex flex-row">
					<input
						type="text"
						value={userTextDraft}
						onChange={e => setUserTextDraft(e.target.value)}
						onKeyDown={e => e.key === "Enter" && sendTextMessage()}
						placeholder={"Write your reply here"}
						className="grow h-12 mr-4 rounded-3xl bg-light-300 border-light-700 border-2 px-5 font-nu text-base"
					/>
					<Button
						fillColor="red-500"
						borderColor="red-900"
						className="h-12 w-12 !rounded-full p-0"
						onClick={sendTextMessage}
					>
						<FeatherIcon
							icon="send"
							size={25}
							stroke="white"
							className="-ml-[3px] -mt-[3px]"
						/>
					</Button>
				</div>
			}

			{ (dateInput || timeInput) &&
				<div className="w-full flex flex-row justify-center">
					<Button
						className="px-[15px] py-2.5 mb-2 rounded-3xl"
						onClick={() => { sendDateTimeMessage() }}
					>
						Date/time picker placeholder: press to continue
					</Button>
				</div>
			}

			{ questPicker &&
				<div className="w-full flex flex-row justify-center">
					<Button
						className="px-[15px] py-2.5 mb-2 rounded-3xl"
						onClick={() => { sendQuestMessage(lastMessage!.input![0].id) }}
					>
						Quest picker placeholder: press to continue
					</Button>
				</div>
			}

			{ storyFinished &&
				<div className="w-full pt-4 flex flex-row justify-center">
					Story completed!
				</div>
			}


			{ showTyping &&
				<div className="absolute top-4 left-4">
					<img
						src="/assets/ubee-head.png"
						alt="Ubee's head"
						className="inline w-[50px] h-[50px] animate-pulse"
					/>
					<span className="ml-2 animate-pulse">Typing...</span>
				</div>
			}
		</div>
	</>);
};

export default ChatWindow;
