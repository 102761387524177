import { ReactNode } from "react";


type ButtonProps = {
	children: ReactNode;
	disabled?: boolean;
	fillColor?: string;
	borderColor?: string;
	className?: string;
	onClick?: () => void;
};

const Button = ({ children, disabled, fillColor, borderColor, className, onClick }: ButtonProps) => {
	return (
		<button
			className={`p-3 border-[2px] border-b-[5px] rounded-2xl
				active:border-b-[2px] active:translate-y-[3px] disabled:active:border-b-[5px] disabled:active:translate-y-0
				${fillColor ? `bg-${fillColor}` : "bg-light-300"}
				${borderColor ? `border-${borderColor}` : "border-light-700"}
				${disabled ? "!bg-gray-300 !border-gray-500 text-gray-700" : ""} 
				${className}
			`}
			disabled={disabled}
			onClick={onClick}
		>
			{ children }
		</button>
	);
};

export default Button;
