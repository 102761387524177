import { useStore } from "./store";
import firebase from "./firebase";
import { get, getDatabase, ref } from "firebase/database";
import { Flow, FlowNodeNext, Msg, MsgID, MsgSender, MsgType, UserInput } from "./types";
import { key, randomPick } from "./helpers";
import checkConditions from "./checkMessageConditions";
import { format } from "date-fns";


const db = getDatabase(firebase);


// List of randomised greetings and how-are-yous
const greetings = ["Hi", "Hi there,", "Hello", "Welcome back,", "Good to see you", "Great you're here,"];
const howAreYous = ["How are you today?", "How have you been?", "How are you feeling today?", "How are you doing today?", "How are things going?"];


const getResponse = async (message: Msg) => {
	// Initialisation
	const store = useStore.getState;
	const currStoryID = store().currStoryID;
	const currStoryFlowID = store().currStoryFlowID;
	const pushMessage = store().pushMessage;

	// Find our specific position in the story
	const flowRef = ref(db, "storyFlows/" + currStoryFlowID);
	const flow = (await get(flowRef)).val() as Flow;
	const flowNode = flow[message.id];

	// Find what comes next
	const allImmResponses = flowNode.next;

	// Only output nodes don't have a next value, so this shouldn't happen
	if (!allImmResponses) {
		const error = "ERROR: no next value. This might be caused because two nodes are disconnected in Botany.";
		console.error(error);

		pushMessage({
			key: key(),
			id: MsgID.ErrorDisconnectedFlow,
			type: MsgType.Error,
			sender: MsgSender.Sys,
			content: error,
			date: Date.now()
		});

		return;
	};


	// Check the conditions of what comes next
	const { checked: checkedImmResponses, free: freeImmResponses } = checkConditions(allImmResponses, message);
	let immResponseID: string;

	// If there are multiple checked and/or free conditions, we don't know which to pick
	if (checkedImmResponses.length > 1 || freeImmResponses.length > 1) {
		const error = "ERROR: multiple valid conditions. This might be caused because conditions were set for the branches after this message but multiple branches are possible and I don't know what to pick";
		console.error(error);

		pushMessage({
			key: key(),
			id: MsgID.ErrorMultipleTrueConditions,
			type: MsgType.Error,
			sender: MsgSender.Bot,
			content: error,
			date: Date.now()
		});

		return;
	}
	// If we match to a checked condition, make that our path
	else if (checkedImmResponses.length === 1) {
		immResponseID = checkedImmResponses[0].id;
	}
	// If we don't match to a checked condition but there is a free one, make that our path
	else if (freeImmResponses.length === 1) {
		immResponseID = freeImmResponses[0].id;
	}
	// If we have neither checked conditions or none conditions, we can't continue
	else {
		const error = "ERROR: no valid condition. This might be caused because conditions were set for the branches after this message but this particular user configuration isn't supported";
		console.error(error);

		pushMessage({
			key: key(),
			id: MsgID.ErrorBadCondition,
			type: MsgType.Error,
			sender: MsgSender.Bot,
			content: error,
			date: Date.now()
		});

		return;
	}


	// Find the immediate response message
	let immResponse = flow[immResponseID];


	// Handle asking for notification permissions
	if (immResponse.type === MsgType.NotifyPerm) {
		let afterResponses: FlowNodeNext[] | undefined;

		const status = window.confirm("This is the notification permissions simulator! Press OK to simulate accepting or Cancel to simulate declining.");
		if (status) afterResponses = immResponse.nextLeft;
		else afterResponses = immResponse.nextRight;

		// Move on to the next message
		if(!afterResponses || afterResponses.length !== 1) {
			pushMessage({
				key: key(),
				id: MsgID.ErrorNotifyPerm,
				type: MsgType.Error,
				sender: MsgSender.Bot,
				content: "ERROR: The nodes are not connected correctly. There are a couple of quirks surrounding the notification permissions node; check with the developers.",
				date: Date.now()
			});

			return;
		}
		
		immResponse = flow[afterResponses[0].id];
	}


	// Check what comes after the immediate response (if anything)
	// to see if we need to provide user input
	const afterResponses = immResponse.next;
	let input: UserInput[] | undefined;

	if(afterResponses) {
		// If there are multiple responses after the immediate one, check their conditions
		const { checked: checkedAfterResponses, free: freeAfterResponses } = checkConditions(afterResponses, message);
		let afterResponseIDs: string[];

		// If there are multiple checked and/or free conditions...
		if (checkedAfterResponses.length > 1 || freeAfterResponses.length > 1 || (checkedAfterResponses.length === 1 && freeAfterResponses.length === 1)) {
			// ...make sure that they're all buttons
			// If some other message type got mixed in, that's a problem
			for(const nodeNext of checkedAfterResponses.concat(freeAfterResponses)) {
				const afterNode = flow[nodeNext.id];
				if(afterNode.type !== MsgType.UserButton) {
					const error = "Error: bad mix of input types. It looks like there were two nodes of different types with a valid condition, for example, a button and a checklist connected to the same node. Check Botany or contact the developers.";
					console.error(error);

					pushMessage({
						key: key(),
						id: MsgID.ErrorInputMix,
						type: MsgType.Error,
						sender: MsgSender.Bot,
						content: error,
						date: Date.now()
					});

					return;
				}
			}

			// Having multiple buttons with valid conditions is fine
			afterResponseIDs = [...checkedAfterResponses.concat(freeAfterResponses)].map(aR => aR.id);
		}
		// If we match to a checked condition, make that our path
		else if (checkedAfterResponses.length === 1) {
			afterResponseIDs = [checkedAfterResponses[0].id];
		}
		// If we don't match to a checked condition but there is a free one, make that our path
		else if (freeAfterResponses.length === 1) {
			afterResponseIDs = [freeAfterResponses[0].id];
		}
		// If we have neither checked conditions nor free ones, we can't continue
		else {
			const error = "ERROR: no valid condition. This might be caused because conditions were set for the branches after this message but this particular user configuration isn't supported";
			console.error(error);

			pushMessage({
				key: key(),
				id: MsgID.ErrorBadCondition,
				type: MsgType.Error,
				sender: MsgSender.Bot,
				content: error,
				date: Date.now()
			});

			return;
		}

		// We now have either just one message of any type or several buttons
		// So we'll check the type of the first message to see what to do
		const firstAfterID = afterResponseIDs[0];
		const firstAfterNode = flow[firstAfterID];

		switch(firstAfterNode.type) {
			case MsgType.UserList:
			case MsgType.UserText:
			case MsgType.UserDate:
			case MsgType.UserTime:
			case MsgType.PickProgram:
			case MsgType.PickQuest:
				input = [{
					type: firstAfterNode.type,
					id: firstAfterID,
					content: firstAfterNode.content,
					effect: firstAfterNode.effect
				}];
				break;
			case MsgType.Output:
				// We've finished this story, push it to history
				store().pushStoryHistory({ storyID: currStoryID, date: new Date() });

				// Add the input
				input = [{
					type: firstAfterNode.type,
					id: firstAfterID,
					content: firstAfterNode.content,
					effect: firstAfterNode.effect
				}];
				break;
			case MsgType.UserButton:
				input = afterResponseIDs.map(id => {
					const afterNode = flow[id];
					return {
						type: afterNode.type,
						id: id,
						content: afterNode.content,
						position: afterNode.position,
						effect: afterNode.effect
					};
				});
				break;
		}
	}

	// Check if the message has randomised content
	const chosenContent = randomPick(immResponse.content);

	// Amend the list of possible greetings depending on the time of day
	const time = parseInt(format(new Date(), "H"));
	if (time >= 4 && time < 12) {
		greetings.push("Good morning,");
	}
	if (time >= 5 && time < 9) {
		greetings.push("Evening,")
	}

	// Interpolate any non-sensitive variables with their content
	// Using replaceAll() somehow leads to a crash on Android so we're using a regex here
	const interpolContent = chosenContent
		.replace(/%GREETING%/g, `${randomPick(greetings)} ${store().userName}!`)
		.replace(/%HOWAREYOU%/g, randomPick(howAreYous))
		.replace(/%DAYOFWEEK%/g, format(new Date(), "EEEE"));


	// Build the message
	const response: Msg = {
		key: key(),
		id: immResponseID,
		type: immResponse.type,
		sender: immResponse.type === MsgType.Output || immResponse.type === MsgType.NotifyPerm ? MsgSender.Sys : MsgSender.Bot,
		content: interpolContent,
		date: Date.now(),
		input: input,
		meta: immResponse.meta,
		effect: immResponse.effect
	};

	// Simulate typing for all visible messages
	await store().simulateTyping(message.content.length, response.content.length);

	// If we're not skipping, display the message
	pushMessage(response);

	// If there's no user reaction after, find what's next
	if (!input) getResponse(response);

	return;
};

export default getResponse;
