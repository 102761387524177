/// <reference lib="dom" />
/// <reference lib="dom.iterable" />
import { useEffect, useState } from "react";
import ChatWindow from "./chat/ChatWindow";
import pkg from "../package.json";
import firebase from "./lib/firebase";
import { getAuth, signInAnonymously } from "firebase/auth";
import getResponse from "./lib/getResponse";
import { key } from "./lib/helpers";
import { useStore } from "./lib/store";
import { MsgSender, MsgType, TestParams } from "./lib/types";


// Set up Firebase
const auth = getAuth(firebase);


// Test links
// https://localhost:3000?story=-NafixnKC8sTQJ9tp1zc&id=-NeNPd11lSWuWUxn_CIl&ver=-NafixnKC8sTQJ9tp1zd
// https://localhost:3000?story=-NbndxFlIF-sL2U-XeOY&id=-NeNSZSFjHV_d4QMmCjD&ver=-NbndxFlIF-sL2U-XeOZ
const urlParams = new URLSearchParams(window.location.search);
const testParams: TestParams = {
	storyID: urlParams.get('story'),
	versionID: urlParams.get('ver')
};


// App component
const App = () => {
	const currStoryFlowID = useStore(state => state.currStoryFlowID);
	const setAppNoStory = useStore(state => state.setAppNoStory);
	const setCurrStoryID = useStore(state => state.setCurrStoryID);
	const setCurrStoryFlowID = useStore(state => state.setCurrStoryFlowID);
	const pushMessage = useStore(state => state.pushMessage);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!loading) return;

		const load = async () => {
			if (!testParams.storyID || !testParams.versionID) {
				setAppNoStory(true);
				setLoading(false);
				return;
			};

			await signInAnonymously(auth);

			// Start the conversation if this conversation isn't already in progress
			if(currStoryFlowID !== testParams.versionID) {
				setCurrStoryID(testParams.storyID);
				setCurrStoryFlowID(testParams.versionID);

				const startMessage = {
					key: key(),
					id: "start",
					type: MsgType.Input,
					sender: MsgSender.Sys,
					date: Date.now(),
					content: testParams.storyID
				};

				pushMessage(startMessage);
				getResponse(startMessage);
			}

			setLoading(false);
		};

		load();
	}, [loading, currStoryFlowID, setAppNoStory, setCurrStoryID, setCurrStoryFlowID, pushMessage]);


	return (
		<div className="h-screen w-full flex flex-col items-center">
			<div className="w-full max-w-[720px] flex flex-row justify-center">
				<div className="flex flex-row items-center">
					<img
						src="/assets/logo.png"
						alt="UB-OK wordmark"
						className="w-32 my-4 mr-8"
					/>
					<span>v{pkg.version}</span>
				</div>
			</div>

			{ loading ?
				<div>Loading...</div>
			:
				<ChatWindow />
			}
		</div>
	);
};

export default App;
