import { ReactNode, useEffect, useState } from "react";
import { useStore } from "../lib/store";
import firebase from "../lib/firebase";
import { getStorage, ref } from "firebase/storage";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { Msg, MsgSender, MsgType } from "../lib/types";
import Lottie from "lottie-react";
import { format } from "date-fns";


type ChatItemProps = {
	msg: Msg;
	msgIndex: number;
	msgList: Msg[];
};

const ChatItem = ({ msg, msgIndex, msgList }: ChatItemProps) => {
	const userName = useStore(state => state.userName);
	const dateOfBirth = useStore(state => state.dateOfBirth);


	// Determine the correct message styles
	const prevSender = msgList[msgIndex - 1]?.sender;
	const nextSender = msgList[msgIndex + 1]?.sender;

	const pos =
		msg.sender === prevSender && msg.sender === nextSender ? "middle" :
		msg.sender !== prevSender && msg.sender === nextSender ? "first" :
		msg.sender === prevSender && msg.sender !== nextSender ? "last" : "sole";

	const rounded =
		pos === "first" ? (msg.sender !== MsgSender.User ? "rounded-bl-lg rounded-tl-3xl" : "rounded-br-lg rounded-tr-3xl") :
		pos === "middle" ? (msg.sender !== MsgSender.User ? "rounded-l-lg" : "rounded-r-lg") :
		pos === "last" ? (msg.sender !== MsgSender.User ? "rounded-tl-lg rounded-bl-3xl" : "rounded-tr-lg rounded-br-3xl") :
		(msg.sender !== MsgSender.User ? "rounded-3xl" : "rounded-3xl");

	const marginBottom = (pos === "first" || pos === "middle") ? "mb-1" : "mb-4";


	// Render the message
	return msg.type === MsgType.BotText || msg.type === MsgType.UserButton || msg.type === MsgType.UserList || msg.type === MsgType.UserDate || msg.type === MsgType.UserTime || msg.type === MsgType.UserText || msg.type === MsgType.PickProgram ?
		<div
			className={`max-w-[300px] px-[15px] py-2.5 mx-4 border-2
				${msg.sender === MsgSender.Bot
					? "self-start bg-yellow-100 border-yellow-500 rounded-r-3xl"
					: "self-end bg-blue-300 border-blue-500 rounded-l-3xl"}
				${rounded} ${marginBottom}
			`}
		>
			{ msg.content
				.replace(/%NAME%/g, userName)
				.replace(/%DATEOFBIRTH%/g, dateOfBirth ? format(new Date(dateOfBirth!), "d MMMM yyyy") : "(no date of birth given)")
			}
		</div>

	: msg.type === MsgType.AddToProgram ?
		<div
			className={`max-w-[300px] px-[15px] py-2.5 mx-4 border-2 self-start bg-apricot-300/50 border-apricot-700 rounded-r-3xl italic
				${rounded} ${marginBottom}
			`}
		>
			In the app, the story would've now been added to the programme!
		</div>

	: msg.type === MsgType.Error ?
		<ErrorMsg rounded={rounded}>
			{ msg.content }
		</ErrorMsg>

	: msg.type === MsgType.BotMedia ?
		<MediaMsg
			content={msg.content}
			type={msg.meta!.type!}
			rounded={rounded}
			marginBottom={marginBottom}
		/>

	: msg.type === MsgType.BotNetImg ?
		<div className={"w-[300px] ml-4"}>
			<img
				// The key ensures that the image properly rerenders on URI or position change
				key={`${msg.content}-${pos}`}
				src={msg.content}
				className={`w-full rounded-r-3xl ${rounded} ${marginBottom}`}
				alt="Taken from the internet"
			/>
		</div>

	: <></>;
};

export default ChatItem;




const storage = getStorage(firebase);

type MediaMsgProps = {
	content: string;
	type: string;
	rounded: string;
	marginBottom: string;
};

const MediaMsg = ({ content, type, rounded, marginBottom }: MediaMsgProps) => {
	const mediaRef = ref(storage, type + "/" + content);
	const [url, loading, error] = useDownloadURL(mediaRef);

	return loading ? (
		<div className="flex-row items-start px-6 py-2">
			Loading...
		</div>
	) :
	error ? (
		<ErrorMsg rounded={rounded}>
			There should be {type === "images" ? "an image" : type === "video" ? "a video" : "something"} here but it's not loading! Sorry about this - it would be amazing if you could go to the Feedback menu and log this problem with the code "{error.code}". Thanks!
		</ErrorMsg>
	) :
	url ? (
		type === "images" ?
			<div className="w-full px-4">
				<img
					key={`${content}-${rounded}`}
					src={url}
					alt={"Descriptions aren't loaded in Botany Test but are available in the app."}
					className={`w-full max-w-[300px] aspect-square rounded-r-3xl ${rounded} ${marginBottom}`}
				/>
			</div>
		: type === "animation" ?
			<AnimationMsg url={url} rounded={rounded} marginBottom={marginBottom} />
		: type === "video" ?
			<div className="w-250px h-250px ml-4 mb-2">
				<video
					src={url}
					className={`w-[250px] h-[250px] rounded-r-3xl ${rounded}`}
					controls
				>
					Video descriptions aren't loaded in Botany Test but are available in the app.
				</video>
			</div>
		:
		<ErrorMsg rounded={rounded}>
			Sorry, this type of media is not supported in the app.
		</ErrorMsg>
	)
	: null;
};




type AnimationMsgProps = {
	url?: string;
	rounded: string;
	marginBottom: string;
};

const AnimationMsg = ({ url, rounded, marginBottom }: AnimationMsgProps) => {
	const [animationData, setAnimationData] = useState<any>();

	useEffect(() => {
		if (!url) return;

		getJSON(url, (status, response) => {
			if(status === 200) {
				setAnimationData(response);
			} else {
				console.error("Couldn't fetch animation data: " + status);
			}
		})
	}, [url]);

	return (
		<div className={`w-[300px] ml-4 aspect-square rounded-r-3xl ${rounded} ${marginBottom} overflow-hidden`}>
			<Lottie
				animationData={animationData}
			/>
		</div>
	);
};

const getJSON = (url: string, callback: (status: number, response: any) => void) => {
	const xhr = new XMLHttpRequest();
	xhr.open("GET", url, true);
	xhr.responseType = "json";
	xhr.onload = function () {
		callback(xhr.status, xhr.response);
	};
	xhr.send();
};




type ErrorMsgProps = {
	children: ReactNode;
	rounded: string;
};

const ErrorMsg = ({ children, rounded }: ErrorMsgProps) => {
	return (
		<div className={`max-w-[300px] px-[15px] py-2.5 mb-4 mx-4 border-2 bg-red-500 border-red-900 text-white rounded-r-3xl ${rounded}`}>
			{ children }
		</div>
	);
};
