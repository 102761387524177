//////// General app types ////////
export enum Mode {
	Dev = "dev",
	Prod = "prod"
};

export type XYPosition = {
	x: number;
	y: number;
};

export type TestParams = {
	storyID: string | null;
	versionID: string | null;
};



//////// Profile types ////////
export enum UserPhysical {
	NauseaMorning = "nauseaMorning",
	Nausea = "nausea",
	BurpWind = "burpWind",
	Bloated = "bloated",
	Toileting = "toileting",
	Diarrhoea = "diarrhoea",
	Constipation = "constipation",
	PainStomach = "painStomach",
	PainGeneral = "painGeneral",
	LumpThroat = "lumpThroat",
	Other = "other",
	None = "none"
};

export enum UserHeardOfFuncSyms {
	Yes = "yes",
	NoNotSure = "noNotSure"
};

export enum UserWorrying {
	Constant = "constant",
	Daily = "daily",
	Sometimes = "sometimes",
	None = "none"
};

export enum UserStoppedActivities {
	Many = "many",
	Some = "some",
	None = "none",
	NoHobbies = "noHobbies"
};

export enum UserExercise {
	Regular = "regular",
	Some = "some",
	NotAble = "notAble",
	Dislike = "dislike"
};

export enum UserLowMood {
	Always = "always",
	OverHalf = "overHalf",
	Several = "several",
	None = "none"
};

export enum UserEnergy {
	High = "high",
	Medium = "medium",
	BoomBust = "boomBust",
	Low = "low"
};

export enum UserSleepTrouble {
	Always = "always",
	OverHalf = "overHalf",
	Several = "several",
	None = "none"
};

export enum UserGoal {
	Body = "body",
	Stress = "stress",
	LowMood = "lowMood"
};


export type CompletedStory = {
	storyID: string;
	date: Date;
};



//////// Message types ////////
export enum MsgID {
	// IDs for special messages
	Start = "start",
	Continue = "continue",
	ErrorBadCondition = "error-bad-condition",
	ErrorDisconnectedFlow = "error-disconnected-flow",
	ErrorInputMix = "error-input-mix",
	ErrorMultipleTrueConditions = "error-multiple-true-conditions",
	ErrorNotifyPerm = "error-notification-permissions",
	ErrorStoryNotFound = "error-story-not-found"
};

export enum MsgType {
	BotText = "botText",
	BotNetImg = "botMedia",   // Relic of the past, as internet images
	BotMedia = "botIntMedia", // are still referred to as type "botMedia"
	UserButton = "userButton",
	UserList = "userList",
	UserText = "userText",
	UserDate = "userDate",
	UserTime = "userTime",
	PickProgram = "pickProgram",
	PickQuest = "pickQuest",
	AddToProgram = "addToProgram",
	NotifyPerm = "notifyPerm",
	Input = "input",
	Output = "output",
	Error = "error"
};

export enum MsgSender {
	Bot = "bot",
	User = "user",
	Sys = "system"
};

export enum ChatBtnType {
	Chat = "chat",
	Rec = "rec",
	Program = "program",
	Quest = "quest",
	Fake = "fake"
};

export enum MsgSaveAs {
	Default = "default",
	FeedbackGeneral = "feedback",
	FeedbackStory = "feedbackStory",
	Name = "saveName",
	DoB = "dob",
	DailyCheck = "dailyCheck",
	DailyCheckDetail = "dailyCheckDetail",
	DailyCheckText = "dailyCheckText",
	Goal = "goal",
	PhysicalSyms = "physical",
	PhysicalSymsOther = "otherPhysical",
	HeardOfFuncSyms = "heardOfFuncSyms",
	PhysicalSymsWorrying = "physicalWorrying",
	StopDoingActivities = "stopDoingActivities",
	StopDoingActivitiesText = "stopDoingActivitiesName", //! Different name in Botany
	ExerciseLevel = "exerciseLevel",
	LowMood = "lowMood",
	EnergyLevel = "energyLevel",
	SleepTrouble = "sleepTrouble",
	NotificationTime = "notifyTime"
};

export type Msg = {
	key: string;
	id: MsgID | string;
	type: MsgType;
	sender: MsgSender;
	date: number;
	content: string;
	input?: UserInput[];
	effect?: MsgEffect;
	meta?: MsgMeta;
};

export type UserInput = {
	id: MsgID | string;
	type: MsgType;
	content: string[];
	position?: XYPosition;
	effect?: MsgEffect;
};

export type MsgEffect = {
	saveAs?: MsgSaveAs;
	payload?: string[];
	hybrid?: boolean;
	listOther?: boolean;
	listUnsure?: boolean;
	listNone?: boolean;
	autoplay?: boolean;
	loop?: boolean;
};

export type MsgMeta = {
	type?: string;
	rawListPayload?: string[];
};



//////// Story types ////////
export enum StoryType {
	Story = "story",
	Test = "test",
	Onboarding = "onboarding",
	ReOnboarding = "reonboarding",
	ChooseProgramme = "chooseProgramme",
	DailyCheck = "dailyCheck"
};

export enum StoryCat {
	Dev = "dev",
	AboutApp = "aboutApp",
	Activity = "activity",
	Body = "body",
	StressAnxiety = "stressAnxiety",
	LowMood = "lowMood",
	BeActive = "beActive",
	Sleep = "sleep",
	Help = "help"
};

export enum StorySubCat {
	Relax = "relax",
	Thinking = "thinking",
	LookAfterYourself = "lookAfterYourself"
};

export type Story = {
	id: string;
	name: string;
	title?: string;
	type: StoryType;
	category: StoryCat;
	subcategory: StorySubCat;
	priority?: number;
	live: boolean;
	desc?: string;
	summary?: string;
	duration?: string;
	quests?: string[];
	flows: Record<string, string>;
	banner?: string;
	bannerColour?: string;
	notes?: Record<string, StoryNote>;
};

export type StoryNote = {
	author: string;
	content: string;
	date: number;
};

export type Flow = Record<string, FlowNode>;

export type FlowNode = {
	type: MsgType;
	content: string[];
	next?: FlowNodeNext[];
	nextLeft?: FlowNodeNext[];
	nextRight?: FlowNodeNext[];
	position: XYPosition;
	meta?: MsgMeta;
	effect?: MsgEffect;
};

export type FlowNodeNext = {
	id: string,
	condition?: FlowNodeCondition
};

export type FlowNodeCondition = {
	op: CondOp;
	trait: CondTrait;
	value: string;
};

export enum CondOp {
	True = "true",
	False = "false",
	Empty = "empty",
	Equals = "equals",
	NotEquals = "notequals",
	Includes = "includes",
	NotIncludes = "notincludes",
	None = "none"
};

export enum CondTrait {
	None = "none",
	Checklist = "checklist",
	History = "history",
	Program = "programme",
	Goal = "goal",
	PhysicalSyms = "physical",
	HeardOfFuncSyms = "heardOfFuncSyms",
	PhysicalSymsWorrying = "physicalWorrying",
	StopDoingActivities = "stopDoingActivities",
	ExerciseLevel = "exerciseLevel",
	LowMood = "lowMood",
	EnergyLevel = "energyLevel",
	SleepTrouble = "sleepTrouble",
	DevMode = "devMode"
};



//////// Program types ////////
export type ProgramNode = {
	nodeID: string;
	storyID: string;
};

export type Program = {
	id: string;
	name: string;
	live: boolean;
	nodes: ProgramNode[];
	description: string;
	banner: string;
	bannerColour?: string;
};
