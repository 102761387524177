// Pick randomly from an array
export const randomPick = <T>(array: T[]) => {
	return array[Math.floor(Math.random() * array.length)];
};


// Capitalise the first letter of a string
// Accurate for all Unicode-supported locales
export const capFirstLetter = ([first, ...rest]: string, locale = navigator.language) => {
	return first === undefined ? "" : first.toLocaleUpperCase(locale) + rest.join("");
};


// Generate a identifier of 24 characters based on the date and some randomness
export const key = () => {
	const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
	let result = new Date().getTime().toString();
	for (let i = 11; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
	return result;
};


// Generate a natural phrase from an array of strings
export const arrayToPhrase = (array: string[]) => {
	const tArray = array.map(el => el.trim());
	let text = "";

	if(array.length === 1) {
		text = tArray[0];
	}
	else if(array.length === 2) {
		text = tArray[0] + " and " + tArray[1];
	}
	else {
		for(let index in tArray) {
			const at = tArray[index];
			
			if(parseInt(index) === tArray.length - 1) {
				text = text.concat("and ", at);
			}
			else {
				text = text.concat(at, ", ");
			}
		}
	}

	return text.toLowerCase();
};
