import { useStore } from "./store";
import { CondOp, CondTrait, FlowNodeCondition, FlowNodeNext, Msg, UserPhysical } from "./types";


// Check a series of messages for their conditions
// The "Checked" array contains all responses that satisfy their condition, if there are any
// The "Free" array contains all responses without a condition
const checkConditions = (nextNodeValues: FlowNodeNext[], message: Msg) => {
	// Look at all that comes next and test their conditions
	// The "Checked" array contains all responses that satisfy their condition, if there are any
	// The "Free" array contains all responses without a condition
	const checkedNextNodeValues: FlowNodeNext[] = [];
	const freeNextNodeValues: FlowNodeNext[] = [];

	for (const nodeNextValue of nextNodeValues) {
		if (nodeNextValue.condition) {
			// Ignore if the condition trait has explicitly set to "none" in Botany
			if (nodeNextValue.condition.trait === CondTrait.None) freeNextNodeValues.push(nodeNextValue);
			// Else check the condition
			else if (checkCondition(nodeNextValue.condition, message)) checkedNextNodeValues.push(nodeNextValue);
		}
		else freeNextNodeValues.push(nodeNextValue);
	}

	return {
		checked: checkedNextNodeValues,
		free: freeNextNodeValues
	};
};

export default checkConditions;



// Check a single condition
const checkCondition = (condition: FlowNodeCondition, message: Msg) => {
	const store = useStore.getState;

	if (condition.trait === CondTrait.Checklist) {
		switch(condition.op) {
			case CondOp.Includes:
				if (message.meta?.rawListPayload?.includes(condition.value)) return true;
				break;
			case CondOp.NotIncludes:
				if (!message.meta?.rawListPayload?.includes(condition.value)) return true;
				break;
			case CondOp.Equals:
				if (message.meta?.rawListPayload?.[0] === condition.value) return true;
				break;
			case CondOp.NotEquals:
				if (message.meta?.rawListPayload?.[0] !== condition.value) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.History) {
		const storyHistory = store().storyHistory;
		const completedStoryIDs = storyHistory.map(sH => sH.storyID);

		switch(condition.op) {
			case CondOp.Includes:
				if (completedStoryIDs.includes(condition.value)) return true;
				break;
			case CondOp.NotIncludes:
				if (!completedStoryIDs.includes(condition.value)) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.Program) {
		switch(condition.op) {
			// The user will never have a programme in the test app
			// So we assert that the programme doesn't include the story
			case CondOp.Includes:
				return false;
			case CondOp.NotIncludes:
				return true;
		}
	}

	else if (condition.trait === CondTrait.Goal) {
		switch(condition.op) {
			case CondOp.Equals:
				if (store().goal === condition.value) return true;
				break;
			case CondOp.NotEquals:
				if (store().goal !== condition.value) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.PhysicalSyms) {
		switch(condition.op) {
			case CondOp.Includes:
				if (store().physicalSymptoms.includes(condition.value as UserPhysical)) return true;
				break;
			case CondOp.NotIncludes:
				if (!store().physicalSymptoms.includes(condition.value as UserPhysical)) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.HeardOfFuncSyms) {
		switch(condition.op) {
			case CondOp.Equals:
				if (store().heardOfFuncSyms === condition.value) return true;
				break;
			case CondOp.NotEquals:
				if (store().heardOfFuncSyms !== condition.value) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.PhysicalSymsWorrying) {
		switch(condition.op) {
			case CondOp.Equals:
				if (store().worrying === condition.value) return true;
				break;
			case CondOp.NotEquals:
				if (store().worrying !== condition.value) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.StopDoingActivities) {
		switch(condition.op) {
			case CondOp.Equals:
				if (store().stoppedActivities === condition.value) return true;
				break;
			case CondOp.NotEquals:
				if (store().stoppedActivities !== condition.value) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.ExerciseLevel) {
		switch(condition.op) {
			case CondOp.Equals:
				if (store().exercise === condition.value) return true;
				break;
			case CondOp.NotEquals:
				if (store().exercise !== condition.value) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.LowMood) {
		switch(condition.op) {
			case CondOp.Equals:
				if (store().lowMood === condition.value) return true;
				break;
			case CondOp.NotEquals:
				if (store().lowMood !== condition.value) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.EnergyLevel) {
		switch(condition.op) {
			case CondOp.Equals:
				if (store().energy === condition.value) return true;
				break;
			case CondOp.NotEquals:
				if (store().energy !== condition.value) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.SleepTrouble) {
		switch(condition.op) {
			case CondOp.Equals:
				if (store().sleepTrouble === condition.value) return true;
				break;
			case CondOp.NotEquals:
				if (store().sleepTrouble !== condition.value) return true;
				break;
		}
	}

	else if (condition.trait === CondTrait.DevMode) {
		switch(condition.op) {
			case CondOp.True:
				if (store().devMode) return true;
				break;
			case CondOp.False:
				if (!store().devMode) return true;
				break;
		}
	}

	return false;
};
